/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import {
	SurveyEntryType,
	SurveyItem,
	Survey,
	ErrorsType,
} from '../types/survey';
import {
	BehaviorSubject,
	Observable,
	distinctUntilChanged,
	map,
	of,
	forkJoin,
	switchMap,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidatorFn,
} from '@angular/forms';
import {
	AttachmentType,
	ExtraOption,
	Field,
	SubsectionType,
} from '../types/fields';
import { CrudService } from '@services/crud-service.service';
import { formStructure } from '@assets/form_schema';
import { extendedValidator } from '../utils/form';
import { PayorBodyType, PayorType, ProgramType } from '../types/payor';
import { OptionType, PatchObjectType, TeamRoleType } from '../types/general';
import { OrgansType } from '../types/general';
import { VALIDATIONS } from '@assets/form_schema/validations';
import { isProgramField, isRoleField } from '../constants/team';
import {
	compact,
	isBoolean,
	isEmpty,
	isNull,
	toInteger,
	toNumber,
} from 'lodash';
import { capitalizeFirstLetter } from '../constants/hospital';
import { MatDialog } from '@angular/material/dialog';
import { AlerModalComponent } from '@components/alerts/alert-modal/alert-modal.component';
import { ModalDataType } from '@screens/survey/survey.component';
import { SnackBarService } from '@services/snack-bar.service';
import {
	ROLE_SCHEMA,
	PROGRAM_SCHEMA,
	CONTACT_SCHEMA,
} from '@assets/form_schema/team';
import {
	RoleProgramCount,
	TeamContactProgram,
	TeamRoleProgramCount,
	TeamUpdateFields,
} from '../types/fields/team';
import { CONTACT_OPTIONS } from '@assets/form_schema/commons';

interface SurveyState {
	surveyItems: SurveyItem[];
	selectedSurvey?: Survey;
	selectedSurveyItem?: SurveyItem;
	selectedTeamMember?: SubsectionType;
	selectedTeamMemberProgam?: SubsectionType;
	selectedContactMember?: SubsectionType;
	fetching: boolean;
	saving: boolean;
	parentForm: FormGroup;
	parentFormErrors: ErrorsType[];
	formTitle: string;
	hospitalName: string;
	hasError: boolean;
	programName: OrgansType;
	programOptions: OptionType[];
	rolesOptions: OptionType[];
	teamMemberSchema: Field[];
	programSchema: Field[];
	contactSchema: Field[];
}
@Injectable({
	providedIn: 'root',
})
export class SurveyStore {
	private _state: BehaviorSubject<SurveyState>;
	constructor(
		private formBuilder: FormBuilder,
		private crudService: CrudService,
		private dialog: MatDialog,
		private snackbarService: SnackBarService
	) {
		this._state = new BehaviorSubject<SurveyState>({
			surveyItems: [],
			selectedSurvey: undefined,
			selectedSurveyItem: undefined,
			selectedTeamMember: undefined,
			selectedTeamMemberProgam: undefined,
			fetching: false,
			saving: false,
			parentForm: this.formBuilder.group({
				sections: this.formBuilder.array([]),
			}),
			parentFormErrors: [],
			formTitle: '',
			hospitalName: '',
			hasError: false,
			programName: null,
			programOptions: [],
			rolesOptions: [],
			teamMemberSchema: ROLE_SCHEMA,
			programSchema: PROGRAM_SCHEMA,
			contactSchema: CONTACT_SCHEMA,
		});
	}

	surveyId = '';
	entryType = '';
	hospitalId = '';
	private year = '';
	private lastReturnedNumber: number | null = null;

	get sections() {
		return this.state.parentForm.controls['sections'] as FormArray;
	}

	/**
	 * The function builds an array of validators based on the validation rules specified in a field
	 * object.
	 * @param {Field} field - The `field` parameter is an object that represents a form field. It likely
	 * contains properties such as `name`, `type`, `label`, and `validate`. The `validate` property is an
	 * array of validation rules for the field. Each validation rule in the array has properties such as
	 * `type
	 * @returns an array of ValidatorFn functions.
	 */
	private buildValidatorsToformControl(field: Field) {
		const validations: ValidatorFn[] = [];
		const dependsOn = field.validate?.some(item => item.dependsOn);
		field.validate?.forEach(validation => {
			if (
				field.showIn?.length &&
				!field.showIn?.includes(this.state.programName)
			) {
				return;
			}

			if (validation.type === 'REQUIRED' && !dependsOn) {
				validations.push(
					extendedValidator({
						required: true,
						message: validation.errorMessage || 'This field is required',
					})
				);
			}
			if (validation.regex) {
				validations.push(
					extendedValidator({
						pattern: validation.regex,
						message: validation.errorMessage || 'This field must be filled',
					})
				);
			}
		});
		return validations;
	}

	buildControlsByField({
		fields,
		group,
	}: {
		fields?: Field[];
		group: Record<string, FormControl | FormGroup | FormArray>;
	}) {
		fields?.forEach(item => {
			if (item.type !== 'paragraph') {
				if (item.type === 'checkbox') {
					// Checbox Config
					const checkboxGroup: Record<string, FormControl> = {};

					item.options?.forEach(opt => {
						checkboxGroup[opt.value] = new FormControl(opt.checked);
					});

					group[item.name] = new FormGroup(
						checkboxGroup,
						this.buildValidatorsToformControl(item)
					);
				} else if (item.type === 'fields') {
					// Fields Inline Config
					const fieldGroup: Record<string, FormControl> = {};

					item.fields?.forEach(field => {
						fieldGroup[field.name] = new FormControl(field.value);
					});

					group[item.name] = new FormGroup(
						fieldGroup,
						this.buildValidatorsToformControl(item)
					);
				} else if (
					item.type === 'radio' &&
					item.style === 'input' &&
					item.nameOption
				) {
					// Fields Inline Config
					group[item.name] = new FormControl(
						item.value,
						this.buildValidatorsToformControl(item)
					);
					group[item.nameOption] = new FormControl(
						item.optionValue,
						this.buildValidatorsToformControl({
							...item,
							validate: item.validateOption || [],
						})
					);
				} else {
					// Field Config
					group[item.name] = new FormControl(
						item.value,
						this.buildValidatorsToformControl(item)
					);
				}
			}
		});
	}

	private buildFormArray(surveyItems: SurveyItem[]) {
		const formArray: FormGroup[] = [];
		surveyItems.forEach(survey => {
			if (isEmpty(survey.subsection)) {
				const group: Record<string, FormControl | FormGroup> = {};
				this.buildControlsByField({
					fields: survey.fields,
					group,
				});

				formArray.push(new FormGroup(group));
			} else {
				const subsectionArray = this.buildTeamFormArray(survey);
				formArray.push(new FormGroup(subsectionArray));
			}
		});
		return formArray;
	}
	buildTeamFormArray(survey: SurveyItem) {
		const myFormArrayAux: FormGroup[] = [];
		survey.subsection?.forEach(subsection => {
			const group: Record<string, FormControl | FormGroup | FormArray> =
				subsection.type !== 'teamContactComponent'
					? {
							programs: new FormArray<any>([]),
					  }
					: {};

			this.buildControlsByField({
				fields: subsection?.fields,
				group: group,
			});

			subsection.subsection?.forEach(subItem => {
				const programGroup: Record<
					string,
					FormControl | FormGroup | FormArray
				> = {};
				this.buildControlsByField({
					fields: subItem?.fields,
					group: programGroup,
				});
				if (subsection.type !== 'teamContactComponent') {
					(group['programs'] as FormArray).push(new FormGroup(programGroup));
				}
			});
			if (!isEmpty(group)) {
				myFormArrayAux.push(new FormGroup(group));
			}
		});

		return myFormArrayAux;
	}
	/**
	 * The `buildForm` function takes an array of survey items and builds a dynamic form with form groups
	 * and form controls based on the survey item configuration.
	 * @param {SurveyItem[]} surveyItems - An array of objects representing survey items. Each survey item
	 * has the following properties:
	 * @returns a FormGroup object.
	 */
	buildForm(surveyItems: SurveyItem[], sectionName = 'sections') {
		const formArray = this.buildFormArray(surveyItems);

		const formGroup = this.formBuilder.group({
			[sectionName]: new FormArray(formArray),
		});
		this.setupConditionalValidations(formGroup, surveyItems, sectionName);
		return formGroup;
	}

	/**
	 * The function sets up conditional validations for a form group based on the survey items provided.
	 * @param {FormGroup} formGroup - The formGroup parameter is an instance of the FormGroup class, which
	 * represents a group of FormControl instances. It is used to manage the form controls and their values
	 * in Angular reactive forms.
	 * @param {SurveyItem[]} surveyItems - An array of objects representing survey items. Each survey item
	 * has a "fields" property which is an array of objects representing individual fields in the survey.
	 * Each field object has a "validate" property which is an array of validation rules for that field.
	 */
	private setupConditionalValidations(
		formGroup: FormGroup,
		surveyItems: SurveyItem[],
		sectionName: string
	) {
		surveyItems.forEach((survey, index) => {
			const _formGroup = formGroup
				.get(sectionName)
				?.get(index.toString()) as any;

			survey.fields.forEach(item => {
				if (item.validate && item.validate?.length > 0) {
					item.validate.forEach(validation => {
						if (validation.dependsOn) {
							validation.dependsOn.forEach(dep => {
								const dependentFieldName = dep.questionName || '';
								const dependentFieldValue = dep.questionValue || '';

								this.addConditionalValidation(
									_formGroup,
									item.name,
									dependentFieldName,
									dependentFieldValue,
									dep.justFilling,
									dep.questionValues,
									dep.withoutValue
								);
							});
						}
					});
				}
				if (item.validateOption && item.validateOption?.length > 0) {
					item.validateOption.forEach(validation => {
						if (validation.dependsOn) {
							validation.dependsOn.forEach(dep => {
								const dependentFieldName = dep.questionName || '';
								const dependentFieldValue = dep.questionValue || '';

								this.addConditionalValidation(
									_formGroup,
									item.nameOption!,
									dependentFieldName,
									dependentFieldValue,
									dep.justFilling,
									dep.questionValues,
									dep.withoutValue
								);
							});
						}
					});
				}
			});
		});
	}
	/**
	 * The function `addConditionalValidation` adds conditional validation to a form group in TypeScript,
	 * where a target field is required based on the value of a dependent field.
	 * @param {FormGroup} formGroup - The formGroup parameter is an instance of the FormGroup class, which
	 * represents a group of FormControl instances. It is used to manage the form controls and their
	 * values within a form.
	 * @param {string} targetFieldName - The targetFieldName parameter is the name of the field in the
	 * formGroup that you want to add conditional validation to. This is the field whose validation will
	 * depend on the value of the dependentFieldName field.
	 * @param {string} dependentFieldName - The name of the field in the form group that the target
	 * field's validation depends on.
	 * @param {any} dependentFieldValue - The `dependentFieldValue` parameter is the value of the
	 * `dependentFieldName` that triggers the conditional validation. When the value of the
	 * `dependentFieldName` matches the `dependentFieldValue`, the `targetFieldName` will be required.
	 * Otherwise, the `targetFieldName` will not have any validators.
	 */
	private addConditionalValidation(
		formGroup: FormGroup,
		targetFieldName: string,
		dependentFieldName: string,
		dependentFieldValue: any,
		justFilling?: boolean,
		multipleValues?: string[],
		withoutValue?: boolean
	) {
		const dependentField = formGroup.get(dependentFieldName) as FormControl;
		dependentField?.valueChanges?.subscribe(value => {
			const targetField = formGroup.get(targetFieldName) as FormControl;
			if (targetField) {
				let hasSomeExtraValidation = false;
				if (value === dependentFieldValue || justFilling) {
					targetField.setValidators([
						extendedValidator({
							required: true,
							message: VALIDATIONS.REQUIRED.errorMessage || '',
						}),
					]);
					hasSomeExtraValidation = true;
				}
				if (
					(value !== dependentFieldValue &&
						!justFilling &&
						!hasSomeExtraValidation) ||
					withoutValue
				) {
					targetField.clearValidators();
				}

				return targetField.updateValueAndValidity();
			}
		});
	}

	onChangeSelectedItem() {
		this.state.selectedSurveyItem?.fields.map(field => {
			if (!field.validate?.length) {
				return false;
			}
			return field.validate.map(validate => validate);
		});
	}

	formatField(field: Field, responseFromApi: any) {
		const currentValue = responseFromApi?.[field.name];
		if (isBoolean(currentValue) && field.type !== 'single-checkbox') {
			field.value = currentValue ? '1' : '0';
			return;
		}
		if (field.type === 'date' && currentValue) {
			field.value = new Date(currentValue).toISOString().split('T')[0];
			return;
		}
		if (field.type === 'radio' && currentValue && field.style === 'input') {
			field.value = currentValue;
			field.optionValue = responseFromApi?.['transplantProgramOther'];
			return;
		}
		if (field.type === 'file') {
			field.attachments = [];
			const tempFiles = responseFromApi?.attachments.filter(
				(file: AttachmentType) => file.entityField === field.name
			);

			if (!isEmpty(tempFiles)) {
				field.attachments = tempFiles;
			}
		}

		field.value = responseFromApi?.[field.name];
		return;
	}

	async onMountState({
		surveyId,
		entryType,
		hospitalId,
		year,
	}: {
		entryType: SurveyEntryType;
		surveyId: string;
		hospitalId: string;
		year: string;
	}) {
		this.setState(() => ({ fetching: true }));
		this.surveyId = surveyId;
		this.entryType = entryType;
		this.hospitalId = hospitalId;
		this.year = year;
		const structure = formStructure[entryType];

		// In the case of payor surveys, the year is used instead of the surveyId, because there is only one payor survey per year.
		this.crudService
			.get(
				`Hospitals/${hospitalId}/${this.entryType}Entries/${
					entryType === 'Payor' ? year : this.surveyId
				}`
			)
			.pipe(
				switchMap((responseFromApi: any) => {
					this.setState(() => ({
						programName: responseFromApi?.['programName']?.toLowerCase(),
					}));
					if (['Hospital', 'Program'].includes(entryType)) {
						structure.sections.forEach(section => {
							section.fields.forEach(field => {
								this.formatField(field, responseFromApi);
							});
						});
						return of({ ...responseFromApi });
					}
					if (entryType === 'Payor') {
						return this.buildPayorsFields(responseFromApi.payorEntries).pipe(
							map(response => {
								const sectionLabel = structure.sections[0].fields[0];
								structure.sections[0].fields = [
									sectionLabel,
									...response,
								];
								return { ...responseFromApi };
							})
						);
					}
					if (entryType === 'Team') {
						return this.buildTeamProgramCountFields(hospitalId, year).pipe(
							map(responseTeamProgramCount => {
								this.state.teamMemberSchema = this.state.teamMemberSchema.map(
									field => {
										if (isProgramField(field)) {
											field.options = responseTeamProgramCount.organOptions;
										}
										if (isRoleField(field)) {
											field.options = responseTeamProgramCount.rolesOptions;
										}
										return field;
									}
								);
								this.state.programSchema = this.state.programSchema.map(
									field => {
										if (isProgramField(field)) {
											field.options = responseTeamProgramCount.organOptions;
										}
										return field;
									}
								);
								this.state.contactSchema = this.state.contactSchema.map(
									field => {
										if (isProgramField(field)) {
											field.options = responseTeamProgramCount.organOptions;
										}
										return field;
									}
								);
								const teamSchema = this.state.teamMemberSchema;
								const programSchema = this.state.programSchema;
								const contactSchema = this.state.contactSchema;

								structure.sections[0].subsection = responseFromApi?.[
									'teamMembers'
								]?.map((member: any) => {
									return {
										id: member.id,
										type: 'teamMemberComponent',
										fields: teamSchema.map(field => {
											if (field.type === 'file') {
												field.attachments = [];
												const tempFiles = this.getAttachmentFile(
													responseFromApi,
													'TEAM',
													member.id,
													field.name
												);

												if (!isEmpty(tempFiles)) {
													field.attachments = tempFiles;
												}
											}

											return {
												...field,
												value:
													field.name === 'roleId'
														? member?.[field.name]?.toString()
														: member?.[field.name],
											};
										}),
										subsection: member?.['teamMemberPrograms']?.map(
											(program: any) => {
												return {
													id: program.teamMemberProgramId,
													parentId: member.id,
													type: 'teamProgramComponent',
													fields: programSchema.map(field => {
														let value = program?.[field.name];
														if (field.type === 'date' && value) {
															value = new Date(value)
																.toISOString()
																.split('T')[0];
														}

														if (field.name === 'programId') {
															value = value?.toString();
														}

														if (field.type === 'file') {
															field.attachments = [];
															const tempFiles = this.getAttachmentFile(
																responseFromApi,
																'TEAM_MEMBER_PROGRAM',
																program.teamMemberProgramId,
																field.name
															);

															if (!isEmpty(tempFiles)) {
																field.attachments = tempFiles;
															}
														}

														return {
															...field,
															value,
														};
													}),
												};
											}
										),
									};
								});
								const sectionLabel = structure.sections[1].fields[0];
								structure.sections[1].fields = [sectionLabel, ...responseTeamProgramCount['matrix']];
								structure.sections[2].subsection = responseFromApi?.[
									'teamContacts'
								]?.map((contact: any) => {
									return {
										id: contact.id,
										type: 'teamContactComponent',
										fields: contactSchema.map(field => {
											let value = contact?.[field.name];
											if (field.name === 'teamContactPrograms') {
												value = compact(
													contact?.teamContactPrograms?.map(
														(item: any) => item?.programId?.toString()
													) || []
												);
											}
											return {
												...field,
												value,
											};
										}),
									};
								});
								structure.sections[3].fields.forEach(field => {
									this.formatField(field, responseFromApi);
								});
								this.setState(() => ({
									programOptions: responseTeamProgramCount.organOptions,
									rolesOptions: responseTeamProgramCount.rolesOptions,
								}));
								return { ...responseFromApi };
							})
						);
					}
					return of({ ...responseFromApi });
				})
			)
			.subscribe(
				(responseFromApi: any) => {
					const sections = structure.sections;
					const form = this.buildForm(sections);
					this.setState(() => ({
						surveyItems: sections,
						selectedSurveyItem: sections[0],
						parentForm: form,
						formTitle: responseFromApi?.['generatedName'],
						hospitalName: responseFromApi?.['hospitalName'],
						fetching: false,
					}));
					if (responseFromApi?.['formStatus'] === 'Submitted') {
						this.state.parentForm.disable();
					}
				},
				() => {
					this.setState(() => ({
						fetching: false,
						hasError: true,
					}));
				}
			);
	}

	/**
	 * The `buildPayorsFields` function constructs an array of 'Payor' and 'Programs' fields fetched from a CRUD service.
	 * It returns an Observable that emits an array of fields.
	 *
	 * First, it fetches 'Payor' and 'Programs' data from the CRUD service. Then, it filters out 'Organ' type programs
	 * and constructs an array of options with their name and id.
	 *
	 * Afterwards, for each fetched 'Payor', it creates a field with the label of the 'Payor' name, type 'checkbox', style 'inline',
	 * the name of the 'Payor' (with white spaces replaced by empty), a colSpan of 12, and the previously constructed options.
	 *
	 * If an error occurs during the data fetching, it logs the error, updates the state to indicate that it's no longer fetching
	 * and that an error has occurred, and returns an Observable that emits an empty array.
	 *
	 * @returns An Observable that emits an array of fields.
	 */
	buildPayorsFields(entries: any): Observable<Field[]> {
		const payors$ = this.crudService.get('Payor') as Observable<PayorType[]>;
		const programs$ = this.crudService.get('Programs') as Observable<
			ProgramType[]
		>;

		return forkJoin([payors$, programs$]).pipe(
			map(([payorsResponse, programsResponse]) => {
				let tempOptions: ExtraOption[] = [];
				programsResponse.forEach((program: ProgramType) => {
					tempOptions.push({
						label: program.name,
						value: program.id,
					});
				});

				const fields: Field[] = [];
				payorsResponse.forEach((payor: PayorType) => {
					tempOptions = tempOptions.map((option: ExtraOption) => {
						const tempCheck = entries.find(
							(entry: any) =>
								entry.payorId === payor.id && entry.programId === option.value
						);

						return {
							...option,
							checked: tempCheck ? true : false,
						};
					});

					fields.push({
						label: payor.name,
						type: 'checkbox',
						style: 'inline',
						name: payor.id.toString(),
						colSpan: 12,
						options: tempOptions,
					});
				});

				return fields;
			}),
			catchError(error => {
				console.log(error);
				this.setState(() => ({
					fetching: false,
					hasError: true,
				}));
				return of([]);
			})
		);
	}

	/**
	 * The `buildTeamProgramCountFields` function constructs an array of 'TeamRole' and 'Programs' fields fetched from a CRUD service.
	 * It returns an Observable that emits an array of fields.
	 *
	 * First, it fetches 'TeamRoles' and 'Programs' data from the CRUD service. Then, it filters out 'Organ' type programs
	 * and constructs an array of temporary fields with their name, placeholder, type, style, and id.
	 *
	 * Afterwards, for each fetched 'TeamRole' of type 'Secondary', it creates a field with the label of the 'TeamRole' name, type 'fields', style 'inline',
	 * the name of the 'TeamRole' (with white spaces replaced by empty), a colSpan of 12, and the previously constructed temporary fields.
	 *
	 * If an error occurs during the data fetching, it logs the error, updates the state to indicate that it's no longer fetching
	 * and that an error has occurred, and returns an Observable that emits an empty array.
	 *
	 * @returns An Observable that emits an array of fields.
	 */
	buildTeamProgramCountFields(
		hospitalId: string,
		year: string
	): Observable<{
		matrix: Field[];
		organOptions: OptionType[];
		rolesOptions: OptionType[];
	}> {
		const teamProgramCountEntries$ = this.crudService.get(
			`Hospitals/${hospitalId}/TeamProgramCount/${year}`
		) as Observable<TeamRoleType[]>;
		const roles$ = this.crudService.get('TeamRoles') as Observable<
			TeamRoleType[]
		>;
		const programs$ = this.crudService.get('Programs') as Observable<
			ProgramType[]
		>;

		return forkJoin([teamProgramCountEntries$, roles$, programs$]).pipe(
			map(([countResponse, rolesResponse, programsResponse]) => {
				const validPrograms = programsResponse;

				const fields: Field[] = [];
				rolesResponse
					.filter((item: TeamRoleType) => item.type === 'Secondary')
					.forEach((team: TeamRoleType) => {
						const tempFields = this.buildTeamProgramCountFieldsWithEntries(
							team.id,
							validPrograms,
							countResponse
						);

						fields.push({
							label: team.name,
							type: 'fields',
							style: 'inline',
							name: team.id.toString(),
							colSpan: 12,
							fields: tempFields,
						});
					});

				return {
					matrix: fields,
					rolesOptions: rolesResponse
						.filter((item: TeamRoleType) => item.type === 'Primary')
						.map(item => ({ label: item.name, value: item.id.toString() })),
					organOptions: validPrograms.map(item => ({
						label: item.name,
						value: item.id.toString(),
					})),
				};
			}),
			catchError(error => {
				console.log(error);
				this.setState(() => ({
					fetching: false,
					hasError: true,
				}));
				return of([]);
			})
		) as any;
	}

	buildTeamProgramCountFieldsWithEntries(
		roleId: number,
		programs: ProgramType[],
		entries: any
	) {
		const tempFields: Field[] = [];
		programs.forEach((program: ProgramType) => {
			const tempEntry = entries.find(
				(entry: any) =>
					entry.roleId === roleId && entry.programId === program.id
			);

			tempFields.push({
				label: program.name,
				placeholder: '#',
				type: 'number',
				style: 'inline',
				name: program.id.toString(),
				value: tempEntry ? tempEntry.amount : null,
			});
		});

		return tempFields;
	}

	setSelectedSurvey(survey: Survey) {
		this.setState(() => ({
			selectedSurvey: survey,
		}));
	}

	get state$(): Observable<SurveyState> {
		return this._state.asObservable();
	}

	get state(): SurveyState {
		return this._state.getValue();
	}
	/**
	 * The `select` function takes a selector function and returns an Observable that emits the selected
	 * state from the state stream, ensuring that only distinct values are emitted.
	 * @param selector - The `selector` parameter is a function that takes the `state` of type
	 * `SurveyState` as input and returns a value of type `K`. It is used to extract a specific piece of
	 * data from the state object.
	 * @returns The `select` method returns an `Observable` of type `K`.
	 */
	select<K>(selector: (state: SurveyState) => K): Observable<K> {
		return this.state$.pipe(map(selector), distinctUntilChanged());
	}

	/**
	 * The `setState` function in TypeScript allows for updating specific properties of the `SurveyState`
	 * object.
	 * @param fn - A function that takes the current state of type SurveyState and returns a partial
	 * object of type E, which is a subset of SurveyState.
	 */
	setState<
		K extends keyof SurveyState,
		E extends Partial<Pick<SurveyState, K>>,
	>(fn: (state: SurveyState) => E): void {
		const state = fn(this.state);
		this._state.next({ ...this.state, ...state });
	}
	/**
	 * The function replaces a survey item in the state with an updated version.
	 * @param {SurveyItem} updatedItem - The updatedItem parameter is an object that represents the
	 * updated survey item. It contains the updated information for the survey item, such as the item's
	 * id, question, options, etc.
	 */
	replaceSurveyItem(updatedItem: SurveyItem) {
		this.setState(state => {
			const updatedItems = state.surveyItems.map(item =>
				item.title === updatedItem.title ? updatedItem : item
			);

			return { surveyItems: updatedItems };
		});
	}
	getformErrors() {
		const sections = this.state.parentForm.get('sections') as any;
		const errors: ErrorsType[] = [];
		sections.controls.forEach((item: any) => {
			const controls = Object.values(item.controls);
			const controlKey = Object.keys(item.controls);
			controls.forEach((control: any, index: number) => {
				if (!control.errors) {
					return;
				}
				const errorsArray = Object.values(control.errors);
				errorsArray?.[0] &&
					errors.push({ error: errorsArray[0], key: controlKey[index] });
			});
		});
		return errors;
	}

	private savePayorForm(hasToSubmit = false) {
		const fomattedFields = this.buildPayorPayload();
		this.crudService
			.post(
				`Hospitals/${this.hospitalId}/PayorEntries/${this.year}/Validate`,
				fomattedFields as any
			)
			.pipe(
				catchError(() => {
					this.showErrorMessage();
					return of([]);
				}),
				switchMap(postResponse => {
					if (hasToSubmit) {
						return forkJoin({
							postResponse: of(postResponse),
							submitResponse: this.crudService.post(
								`Hospitals/${this.hospitalId}/PayorEntries/${this.year}/Submit`
							),
						});
					}
					return of({});
				})
			)
			.subscribe((result: any) => {
				this.markAsSubmitted(result);
				this.showSuccessSaving();
			});
	}

	buildPayorPayload(): PayorBodyType[] {
		const payorSection = this.state.parentForm.value.sections[0];
		const payload: PayorBodyType[] = [];
		for (const payorId in payorSection) {
			for (const programId in payorSection[payorId]) {
				if (payorSection[payorId][programId]) {
					payload.push({
						payorId: toNumber(payorId),
						programId: toNumber(programId),
					});
				}
			}
		}

		return payload;
	}

	savePayorCheckboxChange(
		hospitalId: string,
		year: string,
		payorId: string,
		programId: string
	): Observable<void> {
		return this.crudService.patch(
			`Hospitals/${hospitalId}/PayorEntries/${year}`,
			{ payorId, programId }
		);
	}

	saveEntryChange(
		hospitalId: string,
		entryId: string,
		field: string,
		value: string | number | null
	): Observable<void> {
		const selectedSurveyItems = this.state.selectedSurveyItem;
		const fieldSelected = selectedSurveyItems?.fields.find(
			fieldItem => fieldItem.name === field
		);
		const isBooleanValue =
			fieldSelected?.type !== 'number' && this.validateIfValueIsBoolean(value);

		return this.crudService.patch(
			`Hospitals/${hospitalId}/${this.entryType}Entries/${entryId}`,
			[
				{
					op: 'replace',
					path: `/${capitalizeFirstLetter(field)}`,
					value: isBooleanValue ? Boolean(Number(value)) : value,
				},
			] as any
		);
	}
	saveTeamEntryChange({
		field,
		teamMemberId,
		value,
	}: {
		field: Field;
		value: string | number | null;
		teamMemberId: string | number;
	}) {
		const isBooleanValue =
			this.validateIfValueIsBoolean(value) && field.type === 'radio';

		return this.crudService
			.patch(
				`Hospitals/${this.hospitalId}/TeamEntries/${this.surveyId}/TeamMember/${teamMemberId}`,
				[
					{
						op: 'replace',
						path: `/${capitalizeFirstLetter(field.name)}`,
						value: isBooleanValue ? Boolean(Number(value)) : value,
					},
				] as any
			)
			.subscribe({
				error: () => {
					this.showErrorMessage();
				},
			});
	}
	saveProgramEntryInTeamMemberChange({
		field,
		teamMemberId,
		programId,
		value,
	}: {
		field: Field;
		value: string | number | null;
		teamMemberId: string | number;
		programId: string | number;
	}) {
		const isBooleanValue =
			this.validateIfValueIsBoolean(value) && field.type === 'radio';

		return this.crudService
			.patch(
				`Hospitals/${this.hospitalId}/TeamEntries/${this.surveyId}/TeamMember/${teamMemberId}/TeamMemberProgram/${programId}`,
				[
					{
						op: 'replace',
						path: `/${capitalizeFirstLetter(field.name)}`,
						value: isBooleanValue ? Boolean(Number(value)) : value,
					},
				] as any
			)
			.subscribe({
				error: () => {
					this.showErrorMessage();
				},
			});
	}
	saveTeamContactEntryChange({
		field,
		teamContactId,
		value,
	}: {
		field: Field;
		value: string | number | null;
		teamContactId: string | number;
	}) {
		const isBooleanValue =
			this.validateIfValueIsBoolean(value) && field.type === 'radio';

		return this.crudService
			.patch(
				`Hospitals/${this.hospitalId}/TeamEntries/${this.surveyId}/TeamContact/${teamContactId}`,
				[
					{
						op: 'replace',
						path: `/${capitalizeFirstLetter(field.name)}`,
						value: isBooleanValue ? Boolean(Number(value)) : value,
					},
				] as any
			)
			.subscribe({
				error: () => {
					this.showErrorMessage();
				},
			});
	}
	saveProgramToTeamContactEntryChange({
		teamContactId,
		programId,
		onError,
	}: {
		teamContactId: string | number;
		programId: string | number;
		onError: () => void;
	}) {
		return this.crudService
			.patch(
				`Hospitals/${this.hospitalId}/TeamEntries/${this.surveyId}/TeamContact/${teamContactId}/Program/${programId}`
			)
			.subscribe({
				error: () => {
					this.showErrorMessage();
					onError();
				},
			});
	}

	saveTeamProgramCountChange(
		hospitalId: number,
		year: number,
		roleId: number,
		programId: number,
		amount: number | null
	): Observable<void> {
		return this.crudService.patch(
			`Hospitals/${hospitalId}/TeamProgramCount/${year}`,
			{ roleId, programId, amount } as any
		);
	}

	private validateIfValueIsBoolean(value: any) {
		return typeof value === 'string' && (value === '1' || value === '0');
	}
	private formatFieldAsPayloadToAPI(): PatchObjectType[] {
		const hospitalFields = this.state.parentForm.value as {
			sections: Record<string, any>[];
		};
		const fields = hospitalFields.sections.reduce(
			(acum, curr) => ({ ...curr, ...acum }),
			{}
		);
		const fomattedFields: PatchObjectType[] = [];
		for (const field in fields) {
			const currentValue = fields[field];
			const isBooleanValue = this.validateIfValueIsBoolean(currentValue);
			if (fields[field]) {
				fomattedFields.push({
					op: 'replace',
					path: `/${capitalizeFirstLetter(field)}`,
					value: isBooleanValue ? Boolean(Number(currentValue)) : fields[field],
				});
			}
		}
		return fomattedFields;
	}

	private showSuccessSaving() {
		this.snackbarService.showSucess({ title: 'Data submitted successfully' });
	}

	showErrorMessage(props?: { title?: string }) {
		this.snackbarService.showErrorMessage({ ...props });
	}
	private markAsSubmitted({
		submitResponse,
	}: {
		submitResponse: Record<string, any>;
	}) {
		const isSubmitted = submitResponse?.['formStatus'] === 'Submitted';
		if (isSubmitted) {
			this.state.parentForm.disable();
		}
	}

	private saveHospitalForm(hasToSubmit = false) {
		const fomattedFields = this.formatFieldAsPayloadToAPI();
		this.crudService
			.patch(
				`Hospitals/${this.hospitalId}/HospitalEntries/${this.surveyId}`,
				fomattedFields
			)
			.pipe(
				catchError(() => {
					this.showErrorMessage();
					return of([]);
				}),
				switchMap(patchResponse => {
					if (hasToSubmit) {
						return forkJoin({
							patchResponse: of(patchResponse),
							submitResponse: this.crudService.post(
								`Hospitals/${this.hospitalId}/HospitalEntries/${this.surveyId}/Submit`
							),
						});
					}
					return of({});
				})
			)
			.subscribe((result: any) => {
				this.markAsSubmitted(result);
				this.showSuccessSaving();
			});
	}

	private saveProgramForm(hasToSubmit = false) {
		const fomattedFields = this.formatFieldAsPayloadToAPI();

		return this.crudService
			.patch(
				`Hospitals/${this.hospitalId}/ProgramEntries/${this.surveyId}`,
				fomattedFields
			)
			.pipe(
				catchError(() => {
					this.showErrorMessage();
					return of([]);
				}),
				switchMap(patchResponse => {
					if (hasToSubmit) {
						return forkJoin({
							patchResponse: of(patchResponse),
							submitResponse: this.crudService.post(
								`Hospitals/${this.hospitalId}/ProgramEntries/${this.surveyId}/Submit`
							),
						});
					}
					return of({});
				})
			)
			.subscribe((result: any) => {
				this.markAsSubmitted(result);
				this.showSuccessSaving();
			});
	}
	formatProgramCountField(
		inputData: Record<string, any>[]
	): RoleProgramCount[] {
		const result: RoleProgramCount[] = [];

		for (const roleId in inputData) {
			const programs = inputData[roleId];
			for (const programId in programs) {
				result.push({
					amount: isNull(programs[programId])
						? programs[programId]
						: toInteger(programs[programId]),
					programId: toInteger(programId),
					roleId: toInteger(roleId),
				});
			}
		}
		return result;
	}
	private formatTeamFieldsAsPayloadAPI() {
		const hospitalFields = this.state.parentForm.value;
		const teamSchema = this.state.surveyItems;
		const teamMemberSchema = teamSchema[0].subsection;

		const contactSchema = teamSchema[2].subsection;

		const teamMembers = Object.values(hospitalFields.sections[0]);
		const programCount = hospitalFields.sections[1];
		const contacts = Object.values(hospitalFields.sections[2]);
		const validations = hospitalFields.sections[3];
		const teamRoleProgramCount: TeamRoleProgramCount = {
			hospitalId: toInteger(this.hospitalId),
			year: toInteger(this.year),
			roleProgramCount: this.formatProgramCountField(programCount as any),
		};
		const teamMembersValues: {
			[key: string]: { op: string; path: string; value: any }[];
		} = {};
		const programsValues: {
			[key: string]: {
				op: string;
				path: string;
				value: any;
				parentId: string;
			}[];
		} = {};
		const contactValues: {
			[key: string]: {
				op: string;
				path: string;
				value: any;
			}[];
		} = {};
		const teamContactProgram: TeamContactProgram[] = [];
		teamMembers.forEach((member: any, index) => {
			const currentMemberSchema = teamMemberSchema?.[index] || {
				id: '',
				subsection: [],
			};
			for (const key in member) {
				if (key !== 'programs') {
					teamMembersValues[currentMemberSchema.id] = [
						...(teamMembersValues[currentMemberSchema.id] || []),
						{
							op: 'replace',
							path: `${capitalizeFirstLetter(key)}`,
							value: member[key] || null,
						},
					];
				}
				if (key === 'programs') {
					(member.programs as any[]).forEach((program: any, programIndex) => {
						const currentProgramSchema = currentMemberSchema?.subsection?.[
							programIndex
						] || { id: '' };
						for (const programKey in program) {
							if (programKey !== 'optnMpscLetterAttachments') {
								programsValues[currentProgramSchema.id] = [
									...(programsValues[currentProgramSchema.id] || []),
									{
										op: 'replace',
										path: `${capitalizeFirstLetter(programKey)}`,
										value: program[programKey] || null,
										parentId: currentMemberSchema.id,
									},
								];
							}
						}
					});
				}
			}
		});

		contacts.forEach((contact: any, index) => {
			const currentContactSchema = contactSchema?.[index] || {
				id: '',
				subsection: [],
			};
			for (const key in contact) {
				if (key === 'teamContactPrograms') {
					contact[key]?.forEach((programId: string) => {
						teamContactProgram.push({
							teamContactId: toInteger(currentContactSchema.id),
							entryId: toInteger(this.surveyId),
							programId: toInteger(programId),
						});
					});
				} else {
					contactValues[currentContactSchema.id] = [
						...(contactValues[currentContactSchema.id] || []),
						{
							op: 'replace',
							path: `${capitalizeFirstLetter(key)}`,
							value: contact[key] || null,
						},
					];
				}
			}
		});
		const validationValues: {
			op: string;
			path: string;
			value: any;
		}[] = [];
		for (const key in validations) {
			key &&
				validationValues.push({
					op: 'replace',
					path: `${capitalizeFirstLetter(key)}`,
					value: validations[key] || null,
				});
		}

		const fomattedteamMembers = Object.entries(teamMembersValues).map(
			([id, values]) => ({
				teamMemberId: toInteger(id),
				patch: values,
			})
		);

		const teamMemberPrograms = Object.entries(programsValues).map(
			([id, values]) => ({
				teamMemberProgramId: toInteger(id),
				patch: values.map(entry => ({
					op: entry.op,
					path: entry.path,
					value: entry.value,
				})),
			})
		);

		const teamContacts = Object.entries(contactValues).map(([id, values]) => ({
			teamContactId: toInteger(id),
			patch: values,
		}));
		const payload: TeamUpdateFields = {
			teamMembers: fomattedteamMembers,
			teamMemberPrograms,
			teamContacts,
			teamContactProgram,
			teamRoleProgramCount,
		};

		return {
			payload,
			validationValues,
		};
	}
	private saveTeamForm(hasToSubmit = false) {
		const { payload, validationValues } = this.formatTeamFieldsAsPayloadAPI();
		const multipleResquest: Observable<any>[] = [
			this.crudService.post(
				`Hospitals/${this.hospitalId}/TeamEntries/${this.surveyId}/Validate`,
				payload
			),
			this.crudService.patch(
				`Hospitals/${this.hospitalId}/TeamEntries/${this.surveyId}`,
				validationValues
			),
		];

		forkJoin(multipleResquest).subscribe({
			next: () => {
				this.snackbarService.showInfo({ title: 'Data saved successfully' });
				if (hasToSubmit) {
					this.crudService
						.post(
							`Hospitals/${this.hospitalId}/TeamEntries/${this.surveyId}/Submit`
						)
						.subscribe((submitResponse: any) =>
							this.markAsSubmitted(submitResponse)
						);
				}
			},
			error: () => {
				this.snackbarService.showErrorMessage({});
			},
		});
	}

	saveDataByFormType(hasToSubmit = false) {
		if (this.entryType === 'Hospital') {
			this.saveHospitalForm(hasToSubmit);
		}

		if (this.entryType === 'Program') {
			this.saveProgramForm(hasToSubmit);
		}

		if (this.entryType === 'Payor') {
			this.savePayorForm(hasToSubmit);
		}
		if (this.entryType === 'Team') {
			this.saveTeamForm(hasToSubmit);
		}
	}

	saveData() {
		const isValidForm = this.state.parentForm.status === 'VALID';
		const isTeamMemberFormValid = this.hasCorrectMembers();
		const isTeamContactFormValid = this.hasCorrectContacts();
		const errors = this.getformErrors();

		if (
			errors.length > 0 ||
			!isValidForm ||
			!isTeamMemberFormValid ||
			!isTeamContactFormValid
		) {
			this.state.parentForm.markAllAsTouched();
			let errorMessage =
				'This field is required, please complete all required fields';
			const errorMessageTeamMember =
				'Please provide at least one Primary Surgeon, one Primary Physician and one Transplant Administrator under Primary Roles';
			const errorMessageTeamContact =
				'Please provide at least one medical records contact and one senior transplant coordinator or manager.';

			if (!isTeamMemberFormValid) {
				errorMessage = errorMessageTeamMember;
			}

			if (!isTeamContactFormValid) {
				errorMessage = errorMessageTeamContact;
			}
			this.snackbarService
				.showErrorMessage({
					title: errorMessage,
				})
				.onAction()
				.subscribe(() => {
					// If the user presses the action button, the errors will be saved in the State
					if (!isTeamMemberFormValid) {
						// Open and Scroll to Member Section
						this.setState(() => ({
							selectedSurveyItem: this.state.surveyItems[0],
						}));
					} else if (!isTeamContactFormValid) {
						// Open and Scroll to Contact Section
						this.setState(() => ({
							selectedSurveyItem: this.state.surveyItems[2],
						}));
					} else {
						this.setState(() => ({
							parentFormErrors: errors,
						}));
					}
				});
			return;
		}
		const dialogRef = this.dialog.open(AlerModalComponent<ModalDataType>, {
			data: {
				title: 'Are you sure you would like to submit?',
				message:
					'Once you submit, the form will close and you will not be able to change your responses.',
				buttonText: {
					done: 'Yes, submit this survey',
					cancel: 'No, return to survey',
				},
			},
		});
		dialogRef.afterClosed().subscribe((result: ModalDataType) => {
			if (result.submitted) {
				this.snackbarService.showInfo({ title: 'Saving data' });
				this.saveDataByFormType(true);
			}
		});
	}

	// Validate that Team Survey has the following role types
	// Primary Surgeon
	// Primary Physician
	// Transplant Administrator
	hasCorrectMembers() {
		let response = true;

		if (this.entryType === 'Team') {
			const teamMemberSection = this.state.parentForm.value.sections[0];

			const currentRoles: number[] = [];
			const filteredRoles = this.state.rolesOptions.filter(
				item =>
					item.label.includes('Primary') ||
					item.label.includes('Transplant Administrator')
			);
			for (const key in teamMemberSection) {
				const roleId = teamMemberSection[key].roleId;
				if (filteredRoles.some(role => role.value === roleId)) {
					const findRole = currentRoles.find(item => item === roleId);
					if (!findRole) {
						currentRoles.push(roleId);
					}
				}
			}

			if (currentRoles.length < 3) {
				response = false;
			}
		}

		return response;
	}

	// Validate that Team Survey has the following contact types
	// Medical Records Contact for Program
	// Senior Transplant Coordinator
	hasCorrectContacts() {
		let response = true;

		if (this.entryType === 'Team') {
			const teamContactSection = this.state.parentForm.value.sections[2];
			const currentContacts: string[] = [];
			for (const key in teamContactSection) {
				const contactType = teamContactSection[key].type;
				if (CONTACT_OPTIONS.some(type => type.value === contactType)) {
					const findContact = currentContacts.find(
						item => item === contactType
					);
					if (!findContact) {
						currentContacts.push(contactType);
					}
				}
			}

			if (currentContacts.length < 2) {
				response = false;
			}
		}

		return response;
	}

	// Function that always returns 1 or 2 alternately
	getNumber(): number {
		// If it's the first call, return 1
		if (this.lastReturnedNumber === null) {
			this.lastReturnedNumber = 1;
		} else {
			// If it's not the first call, alternate between 1 and 2
			this.lastReturnedNumber = this.lastReturnedNumber === 1 ? 2 : 1;
		}

		return this.lastReturnedNumber;
	}

	getAttachmentFile(
		responseFromApi: any,
		entityType: string,
		entityID: number,
		entityField: string
	) {
		return responseFromApi.attachments.filter((file: AttachmentType) => {
			return (
				file.entityType === entityType &&
				file.entityID === entityID &&
				file.entityField === entityField
			);
		});
	}
}

import { FormEntryType } from './index';

export const PAYOR_SCHEMA: FormEntryType = {
	formTitle: 'Activate Payor Form',
	formDescription:
		'One record available per institution. Data pre-seeded allowing the user to open forms for those institutions they have authority to access and continue to input information. Ideally, ability to collapse sections for ease in navigation through the form.',
	sections: [
		{
			title: 'Payors',
			fields: [
				{
					label: 'Select payors for receipt of your final RFI submission(s)',
					type: 'paragraph',
					name: '',
				},
			],
		},
	],
};

<div class="card">
	<div class="header">
		<h1 class="truncate-text-2-line">{{ title }}</h1>
		<mat-chip-listbox aria-label="Status" chipListSelectable="false">
			<mat-chip-option
				*ngIf="currentOrgan"
				selectable="false"
				highlighted
				[color]="currentOrgan"
				[ngClass]="{ mix: currentOrgan === 'kidney/pancreas' }">
				<div class="pill-content">
					<img
						[src]="'./assets/icons/' + currentOrgan + '.png'"
						[alt]="currentOrgan"
						[class]="'icon ' + currentOrgan" />
					<span>{{ currentOrgan | titlecase }}</span>
				</div>
			</mat-chip-option>
			<mat-chip-option
				selectable="false"
				highlighted
				[color]="currentStatus?.color"
				>{{ currentStatus?.type | titlecase }}</mat-chip-option
			>
		</mat-chip-listbox>
	</div>
	<div style="flex: 1"></div>

	<div *ngIf="entryType !== 'Payor'" class="progress">
		<div class="title">
			<span [class]="'numercial-data text-' + currentStatus?.color"
				>{{ progress }}%</span
			>
			<span class="mat-body-2">COMPLETION</span>
		</div>

		<app-progress [value]="progress" [color]="currentStatus?.color" />

		<span class="numercial-data-small">{{ lastUpdateDescription }}</span>
	</div>

	<div *ngIf="entryType === 'Payor'" class="space">
		<span class="numercial-data-small">{{ lastUpdateDescription }}</span>
	</div>

	<div *ngIf="!currentStatus?.text" class="no-button"></div>

	<button
		*ngIf="
			currentStatus?.text &&
			showOpenButton &&
			currentStatus?.type !== 'ViewOnly'
		"
		mat-raised-button
		[color]="currentStatus?.color"
		(click)="onSubmit()">
		{{ currentStatus?.text }}
	</button>
	<button
		*ngIf="currentStatus?.type === 'ViewOnly'"
		mat-button
		(click)="onSubmit()">
		{{ currentStatus?.text }}
	</button>
	<div *ngIf="!showOpenButton" style="height: 32px"></div>
</div>

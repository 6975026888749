<mat-sidenav-container class="container">
	<mat-sidenav #sidenav mode="side" [opened]="open" class="side-bar-nav">
		<div class="header">
			<h1 class="mat-h1 h1-color" style="margin-bottom: 4px">{{ title }}</h1>
			<div>
				<mat-chip-option
					*ngIf="currentOrgan"
					selectable="false"
					highlighted
					[color]="currentOrgan"
					[ngClass]="{ mix: currentOrgan.toString() === 'kidney/pancreas' }">
					<div class="pill-content">
						<img
							[src]="'./assets/icons/' + currentOrgan + '.png'"
							[alt]="currentOrgan"
							[class]="'icon ' + currentOrgan" />
						<span>{{ currentOrgan | titlecase }}</span>
					</div>
				</mat-chip-option>
			</div>
			<span class="numeric-text" style="margin-bottom: 32px"
				>{{ subtitle }}
			</span>
		</div>
		<mat-tree
			[dataSource]="dataSource"
			[treeControl]="treeControl"
			class="suvery-tree">
			<ng-container *matTreeNodeDef="let node">
				<mat-tree-node mat-button matTreeNodeToggle *ngIf="showSection(node)">
					<button class="step" (click)="onSelect(node)">
						<app-survey-title
							[selectedSurveyItemId]="selectedSurveyItemId"
							[surveyInfo]="node"
							[startIcon]="node.startIcon"
							[surveyStatus]="getSurveyStatus(node)"></app-survey-title>
					</button>
				</mat-tree-node>
			</ng-container>

			<ng-container *matTreeNodeDef="let node; when: hasChild">
				<mat-nested-tree-node *ngIf="showSection(node)">
					<button class="step" matTreeNodeToggle (click)="onSelect(node)">
						<app-survey-title
							[selectedSurveyItemId]="selectedSurveyItemId"
							[surveyInfo]="node"
							[startIcon]="
								treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'
							"></app-survey-title>
					</button>
					<div
						[class.suvery-tree-invisible]="!treeControl.isExpanded(node)"
						role="group">
						<ng-container matTreeNodeOutlet></ng-container>
					</div>
				</mat-nested-tree-node>
			</ng-container>
		</mat-tree>
		<div class="footer">
			<button
				mat-button
				(click)="onSubmit()"
				class="submit mat-body-1"
				*ngIf="showSubmitButton"
				[disabled]="disabledSubmit">
				<mat-icon>mail_outline</mat-icon>
				Submit
			</button>
			<button mat-button (click)="onExit()" class="exit mat-body-1">
				<mat-icon svgIcon="exit-svg"></mat-icon>
				EXIT
			</button>
		</div>
	</mat-sidenav>

	<mat-sidenav-content class="content">
		<button
			mat-mini-fab
			color="primary"
			aria-label="Open/Close left menu"
			class="open-button"
			(click)="toggleOpenMenu()">
			<mat-icon *ngIf="open">chevron_left</mat-icon>
			<mat-icon *ngIf="!open">chevron_right</mat-icon>
		</button>
		<ng-content></ng-content>
	</mat-sidenav-content>
</mat-sidenav-container>

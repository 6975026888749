import { OptionType } from 'src/app/types/general';
import { SurveyItem } from 'src/app/types/survey';

export const STATES: OptionType[] = [
	{
		value: 'AL',
		label: 'Alabama',
	},
	{
		value: 'AK',
		label: 'Alaska',
	},
	{
		value: 'AZ',
		label: 'Arizona',
	},
	{
		value: 'AR',
		label: 'Arkansas',
	},
	{
		value: 'CA',
		label: 'California',
	},
	{
		value: 'CO',
		label: 'Colorado',
	},
	{
		value: 'CT',
		label: 'Connecticut',
	},
	{
		value: 'DE',
		label: 'Delaware',
	},
	{
		value: 'DC',
		label: 'District of Columbia',
	},
	{
		value: 'FL',
		label: 'Florida',
	},
	{
		value: 'GA',
		label: 'Georgia',
	},
	{
		value: 'HI',
		label: 'Hawaii',
	},
	{
		value: 'ID',
		label: 'Idaho',
	},
	{
		value: 'IL',
		label: 'Illinois',
	},
	{
		value: 'IN',
		label: 'Indiana',
	},
	{
		value: 'IA',
		label: 'Iowa',
	},
	{
		value: 'KS',
		label: 'Kansas',
	},
	{
		value: 'KY',
		label: 'Kentucky',
	},
	{
		value: 'LA',
		label: 'Louisiana',
	},
	{
		value: 'ME',
		label: 'Maine',
	},
	{
		value: 'MD',
		label: 'Maryland',
	},
	{
		value: 'MA',
		label: 'Massachusetts',
	},
	{
		value: 'MI',
		label: 'Michigan',
	},
	{
		value: 'MN',
		label: 'Minnesota',
	},
	{
		value: 'MS',
		label: 'Mississippi',
	},
	{
		value: 'MO',
		label: 'Missouri',
	},
	{
		value: 'MT',
		label: 'Montana',
	},
	{
		value: 'NE',
		label: 'Nebraska',
	},
	{
		value: 'NV',
		label: 'Nevada',
	},
	{
		value: 'NH',
		label: 'New Hampshire',
	},
	{
		value: 'NJ',
		label: 'New Jersey',
	},
	{
		value: 'NM',
		label: 'New Mexico',
	},
	{
		value: 'NY',
		label: 'New York',
	},
	{
		value: 'NC',
		label: 'North Carolina',
	},
	{
		value: 'ND',
		label: 'North Dakota',
	},
	{
		value: 'OH',
		label: 'Ohio',
	},
	{
		value: 'OK',
		label: 'Oklahoma',
	},
	{
		value: 'OR',
		label: 'Oregon',
	},
	{
		value: 'PA',
		label: 'Pennsylvania',
	},
	{
		value: 'RI',
		label: 'Rhode Island',
	},
	{
		value: 'SC',
		label: 'South Carolina',
	},
	{
		value: 'SD',
		label: 'South Dakota',
	},
	{
		value: 'TN',
		label: 'Tennessee',
	},
	{
		value: 'TX',
		label: 'Texas',
	},
	{
		value: 'UT',
		label: 'Utah',
	},
	{
		value: 'VT',
		label: 'Vermont',
	},
	{
		value: 'VA',
		label: 'Virginia',
	},
	{
		value: 'WA',
		label: 'Washington',
	},
	{
		value: 'WV',
		label: 'West Virginia',
	},
	{
		value: 'WI',
		label: 'Wisconsin',
	},
	{
		value: 'WY',
		label: 'Wyoming',
	},
];

export const yesNoOptions: OptionType[] = [
	{
		label: 'Yes',
		value: '1',
	},
	{
		label: 'No',
		value: '0',
	},
];

export const titleOptions: OptionType[] = [
	{
		label: 'Dr.',
		value: 'Dr.',
	},
	{
		label: 'Mr.',
		value: 'Mr.',
	},
	{
		label: 'Mrs.',
		value: 'Mrs.',
	},
	{
		label: 'Ms.',
		value: 'Ms.',
	},
	{
		label: 'Other',
		value: 'Other',
	},
];

export const organs: OptionType[] = [
	{
		label: 'Kidney',
		value: 'kidney',
	},
	{
		label: 'Liver',
		value: 'liver',
	},
	{
		label: 'Heart',
		value: 'heart',
	},
];

export const SUBMIT_SECTION: SurveyItem = {
	title: 'Verify/Validate and Submit Data',
	fields: [
		{
			label:
				'I verify all information contained within has been approved and reviewed by my medical director.',
			type: 'single-checkbox',
			name: 'medicalDirectorApproval',
			colSpan: 12,
			options: yesNoOptions,
			validate: [
				{
					regex: /true/,
					type: 'REQUIRED',
					errorMessage:
						'To submit, please check all boxes to verify the data entered is valid.',
				},
			],
		},
		{
			label:
				'I verify all information contained within has been approved and reviewed by my surgical director.',
			type: 'single-checkbox',
			name: 'surgicalDirectorApproval',
			colSpan: 12,
			validate: [
				{
					regex: /true/,
					type: 'REQUIRED',
					errorMessage:
						'To submit, please check all boxes to verify the data entered is valid.',
				},
			],
		},
		{
			label:
				'I certify that all program data for my institution is accurate and has been completed to the best of my knowledge.',
			type: 'single-checkbox',
			name: 'dataCertification',
			colSpan: 12,
			validate: [
				{
					regex: /true/,
					type: 'REQUIRED',
					errorMessage:
						'To submit, please check all boxes to verify the data entered is valid.',
				},
			],
		},
		{
			label: 'Submit',
			type: 'submit-button',
			name: '',
		},
	],
};

export const CATEGORY_OPTIONS: OptionType[] = [
	{
		label: 'Adult',
		value: 'adult',
	},
	{
		label: 'Pediatric',
		value: 'pediatric',
	},
	{
		label: 'Both',
		value: 'both',
	},
];

export const CATEGORY_OPTIONS_NONE: OptionType[] = [
	{
		label: 'Adult',
		value: 'adult',
	},
	{
		label: 'Pediatric',
		value: 'pediatric',
	},
	{
		label: 'Both',
		value: 'both',
	},
	{
		label: 'None',
		value: 'none',
	},
];

export const CONTACT_OPTIONS: OptionType[] = [
	{
		label: 'Medical Records Contact for Program',
		value: 'Medical Records Contact for Program',
	},
	{
		label: 'Senior Transplant Coordinator / Manager',
		value: 'Senior Transplant Coordinator / Manager',
	},
];
